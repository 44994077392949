@font-face {
    font-family: 'AlbraDisplayBold';
    src: url('/fonts/Albra-Display-Bold-eot') format('eot');
    src: url('/fonts/Albra-Display-Bold.woff2') format('woff2'), url('/fonts/Albra-Display-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'BarlowCondensedRegular';
    src: url('/fonts/barlowcondensed-regular-webfont.eot');
    src: url('/fonts/barlowcondensed-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('/fonts/barlowcondensed-regular-webfont.woff2') format('woff2'),
        url('/fonts/barlowcondensed-regular-webfont.woff') format('woff'),
        url('/fonts/barlowcondensed-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BarlowCondensedBold';
    src: url('/fonts/barlowcondensed-bold-webfont.eot');
    src: url('/fonts/barlowcondensed-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('/fonts/-bold-webfont.woff2') format('woff2'),
        url('/fonts/barlowcondensed-bold-webfont.woff') format('woff'),
        url('/fonts/barlowcondensed-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.button-primary {
    @apply transition transform duration-300 ease-in-out text-white py-1 px-4 rounded-md shadow-sm w-full bg-blue-700 hover:bg-blue-800 hover:shadow-xl;
}

.firstChild {
    > :first-child {
        > :first-child {
            > :last-child {
                > :first-child {
                    @apply invisible;
                }
            }
        }
    }
}

.lastChild {
    > :last-child {
        > :last-child {
            > :last-child {
                > :last-child {
                    > :last-child {
                        @apply invisible;
                    }
                }
            }
        }
    }
}

.button-black {
    @apply rounded h-12 w-full bg-black text-white text-xl px-8 duration-200 transform-gpu filter hover:scale-105 cursor-pointer hover:shadow-md;
}

.button-black-disabled {
    @apply rounded h-12 w-full bg-black text-white text-xl px-8 cursor-not-allowed;
}

.button-red {
    @apply rounded h-12 w-full bg-red-500 text-white text-xl px-8 hover:bg-red-600 transform duration-100 cursor-pointer hover:shadow-md;
}

.button-gray {
    @apply rounded h-12 w-full bg-gray-500 text-white text-xl px-8 duration-200 transform-gpu filter hover:scale-105 cursor-pointer hover:shadow-md;
}

.input {
    @apply relative;

    .is-invalid {
        @apply border-2;
    }

    &__label {
        @apply left-3 top-3 lg:top-2 px-2 absolute bg-white whitespace-nowrap text-gray-400;
        transform: translate(0, 0);
        transform-origin: 0 0;
        transition: transform 120ms ease-in;
    }

    &__error {
        @apply whitespace-nowrap block w-full pr-2 text-right font-light text-gray-800;
    }

    &__field {
        @apply block py-2 px-6 w-full box-border border rounded-lg border-gray-200 shadow-md text-gray-900;

        &:focus,
        &:not(:placeholder-shown) {
            & + .input__label {
                transform: translate(-0.15rem, -100%) scale(0.9);
                @apply font-semibold text-gray-400;
            }

            &:disabled + .input__label {
                @apply text-gray-600;
            }
        }
    }
}

.outsideBackground {
    background-image: url(/img/backgrounds/untitled-4.jpg);
    background-repeat: no-repeat;
    background-position: center;
    /* box-shadow: inset 8000px 0 0 0 rgba(0, 0, 0, 0.5); */
    border-color: rgba(0, 0, 0, 1);
    background-size: cover;
}

@import '../../node_modules/vue-select/src/scss/vue-select.scss';
.v-select {
    &.is-invalid {
        > .vs__dropdown-toggle {
            @apply border-2 border-red-500;
        }
    }
}

/* tinyMCE */
.tinymce {
    > h6 {
        @apply mb-2 font-bold text-xl;
    }

    ul {
        @apply ml-10 mb-4 list-disc;
    }

    ol {
        @apply ml-10 mb-4 list-decimal;
    }

    a {
        @apply mb-4 text-icon-indigo hover:font-bold;
    }
}

.vs {
    &__dropdown-toggle {
        @apply py-2 px-4 w-full box-border border rounded-lg bg-white border-gray-200 shadow-md text-gray-900;
    }

    &__dropdown-menu {
        @apply border-gray-200 shadow-md p-0;
        top: calc(100% - 1px);
    }

    &__dropdown-option {
        @apply border-t border-gray-200;
    }

    &__selected-options {
        @apply p-0;
    }

    &__selected {
        @apply p-0;
    }

    &__error {
        @apply whitespace-nowrap block w-full pr-2 text-right font-light text-gray-800;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

//Disable Scroll when class modal-open is active
body.modal-open {
    overflow: hidden;
}
